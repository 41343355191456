(function() {
    'use strict';

    var FORM_VALIDATION = {
        REQUIRED: 'To pole jest wymagane',
        EMAIL: 'E-mail jest niepoprawny'
    };

    function addBodyLayer() {
        $('body').addClass('is-layer');
    }

    function removeBodyLayer() {
        $('body').removeClass('is-layer');
    }

    function Body() {

        function closeVideo() {
            var vid1 = document.getElementById("video1");
            var vid2 = document.getElementById("video2");
            var vid3 = document.getElementById("video3");
            var vid4 = document.getElementById("video4");
            var vid5 = document.getElementById("video5");
            var vid6 = document.getElementById("video6");
            var vid7 = document.getElementById("video7");

            vid1.autoplay = false;
            vid1.load();

            vid2.autoplay = false;
            vid2.load();

            vid3.autoplay = false;
            vid3.load();

            vid4.autoplay = false;
            vid4.load();

            vid5.autoplay = false;
            vid5.load();

            vid6.autoplay = false;
            vid6.load();

            vid7.autoplay = false;
            vid7.load();
        }

        $('#button-v1').on('click', function() {
            var vid = document.getElementById("video1");
            vid.autoplay = true;
            vid.load();
        });

        $('#button-v2').on('click', function() {
            var vid = document.getElementById("video2");
            vid.autoplay = true;
            vid.load();
        });

        $('#button-v3').on('click', function() {
            var vid = document.getElementById("video3");
            vid.autoplay = true;
            vid.load();
        });

        $('#button-v4').on('click', function() {
            var vid = document.getElementById("video4");
            vid.autoplay = true;
            vid.load();
        });

        $('#button-v5').on('click', function() {
            var vid = document.getElementById("video5");
            vid.autoplay = true;
            vid.load();
        });

        $('#button-v6').on('click', function() {
            var vid = document.getElementById("video6");
            vid.autoplay = true;
            vid.load();
        });

        $('#button-v7').on('click', function() {
            var vid = document.getElementById("video7");
            vid.autoplay = true;
            vid.load();
        });

        $('.modal .close').on('click', function() {
            //closeVideo();
        });

        $('body > .layer').on('click', function() {
            //closeVideo();
            removeBodyLayer();

            $('.modal').fadeOut(250);
        });
    }

    function Header() {
        var $header = $('.header'),
            $inspirations = $('#section-inspirations'),
            $program = $('#section-program'),
            $media = $('#section-media'),
            $speakers = $('#section-speakers'),
            //$register = $('#section-register'),
            $localization = $('#section-localization'),
            $contact = $('#section-contact'),
            $partners = $('#section-partners');

        $(window).on('scroll', function() {
            var OFFSET = 150,
                windowScrollTop = $(this).scrollTop(),
                $menuElem;

            if ($(window).width() <= 960) {
                if (windowScrollTop > ($media.offset().top - OFFSET)) {
                    $header.fadeIn(250);
                } else {
                    $header.fadeOut(250);
                }

                return false;
            }


            if (windowScrollTop > ($media.offset().top - OFFSET)) {
                $header.fadeIn(250);
                $menuElem = $header.find('.menu-media');

            } else {
                $header.fadeOut(250);
                $header.find('.nav a.active').removeClass('active');
            }

            if (windowScrollTop > ($inspirations.offset().top - OFFSET)) {
                $menuElem = $header.find('.menu-inspirations');
            }

            if (windowScrollTop > ($program.offset().top - OFFSET)) {
                $menuElem = $header.find('.menu-program');
            }

            if (windowScrollTop > ($speakers.offset().top - OFFSET)) {
                $menuElem = $header.find('.menu-speakers');
            }

            // if (windowScrollTop > ($register.offset().top - OFFSET)) {
            //     $menuElem = $header.find('.menu-register');
            // }

            if (windowScrollTop > ($localization.offset().top - OFFSET)) {
                $menuElem = $header.find('.menu-localization');
            }

            if (windowScrollTop > ($contact.offset().top - OFFSET)) {
                $menuElem = $header.find('.menu-contact');
            }

            if (windowScrollTop > ($partners.offset().top - OFFSET)) {
                $menuElem = $header.find('.menu-partners');
            }

            if (typeof $menuElem === 'undefined') {
                return false;
            }

            if (!$menuElem.hasClass('active')) {
                $header.find('.nav a.active').removeClass('active');
                $menuElem.addClass('active');
            }
        });

        $('.header .icon-hamburger').on('click', function() {
            if ($('.header .nav').hasClass('open')) {
                $('.header .nav').removeClass('open');
                $('html').removeClass('no-scroll');
            } else {
                $('.header .nav').addClass('open');
                $('html').addClass('no-scroll');
            }
        });

        $('.header .nav a').on('click', function() {
            if ($(window).width() <= 960) {
                if (!$(this).hasClass('active')) {
                    $header.find('.nav a.active').removeClass('active');
                    $(this).addClass('active');
                }
                $('.header .nav').removeClass('open');
            }
        });
    }

    function ScrollTo() {
        $('.scroll-to').on('click', function() {
            var attr = $(this).attr('data-scroll-to'),
                $section = $(attr);

            $('html, body').animate({
                scrollTop: $section.offset().top - 100
            }, 1500, function() {
                if (attr === '#section-slider') {
                    return false;
                }

                $('.header').fadeIn(250);
            });
        });
    }

    function Tabs() {
        $('.tab-list .tab').on('click', function() {
            var index = $(this).index();

            if (!$(this).hasClass('active')) {
                $('.tab-list .tab.active').removeClass('active');
                $('.tab-content-list .tab-content.active').removeClass('active').hide();


                $(this).addClass('active');

                $('.tab-content-list .tab-content:eq(' + index + ')').fadeIn(250, function() {
                    $(this).addClass('active');
                });
            }
        });

        $('.tab-content .program.is-opening').on('click', function() {
            var $this = $(this);

            if ($this.hasClass('animation')) {
                return false;
            }

            if ($(this).hasClass('open')) {
                $this.removeClass('open');
                $this.addClass('animation');

                $(this).find('.program-info .desc').slideUp(250, function() {
                    $this.removeClass('animation');
                });
            } else {
                $this.addClass('open');
                $this.addClass('animation');

                $(this).find('.program-info .desc').slideDown(250, function() {
                    $this.removeClass('animation');
                });
            }
        });
    }

    function Slider() {
        $('#button-v7').on('click', function() {
            addBodyLayer();
            $(this).closest('.slider-container').find('.modal').fadeIn(250);
        });

        $('.slider-container .modal .close').on('click', function() {
            removeBodyLayer();

            $(this).closest('.modal').fadeOut(250);
        });
    }

    function Speakers() {

        $('.speaker-list').owlCarousel({
            nav: true,
            responsive: {
                0: {
                    items: 1
                },
                641: {
                    items: 2
                },
                1100: {
                    items: 3
                },
                1340: {
                    items: 4
                }
            },
            onInitialized: function() {
                loadMore();
            },
            onTranslate: function() {
                // console.log('translate');
                // $('.speaker').addClass('hover-disabled');
                // $('.speaker').off('click hover');
            },
            onTranslated: function() {
                // console.log('translated');
                // setTimeout(function() {
                //     $('.speaker').removeClass('hover-disabled');
                // }, 300);

            }
        });

        function loadMore() {
            var $seeMore = $('.see-more'),
                content = $seeMore.data('content');

            $seeMore.on('click', function() {
                var content = $(this).data('content');
                $.magnificPopup.open({
                    items: [{
                        src: $('<div class="white-popup">' + content + '</div>')
                    }],
                    type: 'inline',
                    midClick: true
                });
            });

        }

        $('.speaker').on('click', function() {
            var $this = $(this);

            // console.log($(this).hasClass('hover-disabled'));

            if (!$(this).hasClass('open')) {
                $(this).find('.layer').fadeIn(250, function() {
                    $this.addClass('open');
                });
            }
        });

        $('.speaker .layer .close').on('click', function() {
            if ($(this).closest('.speaker').hasClass('open')) {
                $(this).closest('.layer').fadeOut(250, function() {
                    $(this).closest('.speaker').removeClass('open');
                });
            }
        });

        $('.speaker .video-button').on('click', function() {
            addBodyLayer();

            $(this).closest('.speaker').find('.modal').fadeIn(250);
        });

        $('.speaker .modal .close').on('click', function() {
            removeBodyLayer();

            $(this).closest('.modal').fadeOut(250);
        });
    }

    function scrollToErrorField() {
        var $errField = $('.field-errors').not(':empty').first().parent('.form-field');
        $('html, body').animate({
            scrollTop: $errField.offset().top - 120
        }, 300);
    }

    function Register() {
        $('.form-register .add-person').on('click', function() {
            //$(this).remove();
            if ($('.is-addon-person')[0]) {
                $('.form-register').find('.form-addon-person').slideUp(250, function() {
                    $(this).removeClass('is-addon-person');
                    $('.add-person span').eq(1).html('Dodaj osobę towarzyszącą');
                    $('.sprite-icon-add-18x18').removeClass('removePerson');
                    $(this).find('.addon-person-hidden').val(0);
                    $('#client_form_partnerName').val('');
                });
            } else {
                $('.form-register').find('.form-addon-person').fadeIn(250, function() {
                    $(this).addClass('is-addon-person');
                    $('.sprite-icon-add-18x18').addClass('removePerson');
                    $('.add-person span').eq(1).html('Usuń osobę towarzyszącą');
                    $(this).find('.addon-person-hidden').val(1);
                });
            }
        });

        $('.form-register .modal .close').on('click', function() {
            removeBodyLayer();

            $(this).parents('.modal').fadeOut(250);
        });

        $('.form-register .form-buttons .show-popup').on('click', function() {
            if (validateForm()) {
                addBodyLayer();
                $(this).parents('.form-register').find('.modal').fadeIn(250);
            } else {
                scrollToErrorField();
            }
        });

        $('.form-register .form-buttons .send-form').on('click', function(e) {
            e.preventDefault();

            if (validateFormTerms()) {
                if (!$('.is-addon-person')[0]) {
                    $('#client_form_partnerName').val('');
                }
                $(this).parents('.form-register').submit();
            }
        });
    }

    function validateForm() {
        var isValid = true,
            $surname = $('.field-surname'),
            $email = $('.field-email'),
            $company = $('.field-company'),
            $addonPerson = $('.field-addon-person'),
            $room = $('.field-room'),
            $formAddonPerson = $('.form-addon-person');

        if ($surname.find('input').val() === '') {
            isValid = false;

            $surname.find('.field-errors').text(FORM_VALIDATION.REQUIRED).fadeIn(250);
        } else {
            $surname.find('.field-errors').text('').fadeOut(250);
        }

        if ($email.find('input').val() === '') {
            isValid = false;

            $email.find('.field-errors').text(FORM_VALIDATION.REQUIRED).fadeIn(250);
        } else {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test($email.find('input').val())) {
                $email.find('.field-errors').text('').fadeOut(250);
            } else {
                isValid = false;

                $email.find('.field-errors').text(FORM_VALIDATION.EMAIL).fadeIn(250);
            }
        }

        if ($company.find('input').val() === '') {
            isValid = false;

            $company.find('.field-errors').text(FORM_VALIDATION.REQUIRED).fadeIn(250);
        } else {
            $company.find('.field-errors').text('').fadeOut(250);
        }

        if (parseInt($formAddonPerson.find('.addon-person-hidden').val())) {
            if ($addonPerson.find('input').val() === '') {
                isValid = false;

                $addonPerson.find('.field-errors').text(FORM_VALIDATION.REQUIRED).fadeIn(250);
            } else {
                $addonPerson.find('.field-errors').text('').fadeOut(250);
            }

            if ($room.find('select').val() === '') {
                isValid = false;

                $room.find('.field-errors').text(FORM_VALIDATION.REQUIRED).fadeIn(250);
            } else {
                $room.find('.field-errors').text('').fadeOut(250);
            }
        }

        return isValid;
    }

    function validateFormTerms() {
        var isValid = true,
            $term1 = $('input[type="checkbox"].term-1'),
            $term2 = $('input[type="checkbox"].term-2');

        if (!$term1.is(":checked")) {
            isValid = false;

            $term1.closest('.form-field').find('.field-errors').text(FORM_VALIDATION.REQUIRED).fadeIn(250);
        } else {
            $term1.closest('.form-field').find('.field-errors').text('').fadeOut(250);
        }

        return isValid;
    }

    function Localization() {
        $('.section-localization .localization-map-button').on('click', function() {
            addBodyLayer();

            $(this).parents('.section-localization').find('.modal').fadeIn(250, function() {
                var localizationMap = new LocalizationMap();

                localizationMap.init();
            });
        });

        $('.section-localization .modal .close').on('click', function() {
            removeBodyLayer();

            $(this).parents('.modal').fadeOut(250);
        });
    }

    function LocalizationMap() {
        var map,
            marker,
            contentString,
            infoWindow,
            myLatlng,
            mapOptions;

        myLatlng = new google.maps.LatLng(51.7786041, 19.448198);

        mapOptions = {
            zoom: 16,
            center: myLatlng,
            scrollwheel: false,
            styles: [{
                    "featureType": "water",
                    "elementType": "geometry",
                    "stylers": [{
                            "color": "#e9e9e9"
                        },
                        {
                            "lightness": 17
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "geometry",
                    "stylers": [{
                            "color": "#f5f5f5"
                        },
                        {
                            "lightness": 20
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry.fill",
                    "stylers": [{
                            "color": "#ffffff"
                        },
                        {
                            "lightness": 17
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry.stroke",
                    "stylers": [{
                            "color": "#ffffff"
                        },
                        {
                            "lightness": 29
                        },
                        {
                            "weight": 0.2
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "geometry",
                    "stylers": [{
                            "color": "#ffffff"
                        },
                        {
                            "lightness": 18
                        }
                    ]
                },
                {
                    "featureType": "road.local",
                    "elementType": "geometry",
                    "stylers": [{
                            "color": "#ffffff"
                        },
                        {
                            "lightness": 16
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "geometry",
                    "stylers": [{
                            "color": "#f5f5f5"
                        },
                        {
                            "lightness": 21
                        }
                    ]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "geometry",
                    "stylers": [{
                            "color": "#dedede"
                        },
                        {
                            "lightness": 21
                        }
                    ]
                },
                {
                    "elementType": "labels.text.stroke",
                    "stylers": [{
                            "visibility": "on"
                        },
                        {
                            "color": "#ffffff"
                        },
                        {
                            "lightness": 16
                        }
                    ]
                },
                {
                    "elementType": "labels.text.fill",
                    "stylers": [{
                            "saturation": 36
                        },
                        {
                            "color": "#333333"
                        },
                        {
                            "lightness": 40
                        }
                    ]
                },
                {
                    "elementType": "labels.icon",
                    "stylers": [{
                        "visibility": "off"
                    }]
                },
                {
                    "featureType": "transit",
                    "elementType": "geometry",
                    "stylers": [{
                            "color": "#f2f2f2"
                        },
                        {
                            "lightness": 19
                        }
                    ]
                },
                {
                    "featureType": "administrative",
                    "elementType": "geometry.fill",
                    "stylers": [{
                            "color": "#fefefe"
                        },
                        {
                            "lightness": 20
                        }
                    ]
                },
                {
                    "featureType": "administrative",
                    "elementType": "geometry.stroke",
                    "stylers": [{
                            "color": "#fefefe"
                        },
                        {
                            "lightness": 17
                        },
                        {
                            "weight": 1.2
                        }
                    ]
                }
            ]
        };

        contentString = '' +
            '<div class="company-window">' +
            '<div class="map-company-name"><b>Hotel Andel&#8217;s</b></div>' +
            '<div>Ogrodowa 17</div>' +
            '<div>90-001 Łódź</div>' +
            // '<div class="map-hotel-url"><a href="http://www.hotelnarvil.pl" target="_blank">www.hotelnarvil.pl</a></div>' +
            '</div>';

        infoWindow = new google.maps.InfoWindow({
            content: contentString
        });

        function events() {
            google.maps.event.addListener(marker, 'click', function() {
                infoWindow.open(map, marker);
            });
        }

        function initMap() {
            map = new google.maps.Map(document.getElementById('localization-map'), mapOptions);

            marker = new google.maps.Marker({
                position: myLatlng,
                map: map,
                title: 'Hotel Andel&#8217;s'
            });

            events();
        }

        function init() {
            initMap();

            $(window).on('resize', function() {
                $('#localization-map').attr('style', '');

                initMap();
            });
        }

        return {
            init: init
        }
    }

    if ($.cookie('pb-cookies') !== '1') {
        $('.cookies').fadeIn(250);
    }

    $('.cookies .button-ok').on('click', function() {
        $('.cookies').fadeOut(250, function() {
            $.cookie('pb-cookies', '1', {
                expires: 365,
                path: '/'
            });
        });
    });


    function Gallery() {
        var mediaList = $('.media-list'),
            loadMoreBtn = $('.gallery-load-more'),
            videoItem = $('.media-item-video'),
            magnificPopup = $.magnificPopup.instance,
            hammerElem,
            currentLastItem = 19,
            mediaLoadCounter = 20,
            maxItems = 167,
            swipeAdded = false;

        function mediaItemTemplate(itemCounter) {
            itemCounter = itemCounter < 10 ? '00' + itemCounter : itemCounter < 100 ? '0' + itemCounter : itemCounter;
            return '<li class="media-item hidden"><a href="/bundles/portal/images/media/full-size/pharmacybusiness_' + itemCounter + '.jpg" style="background-image:url(/bundles/portal/images/media/min/pharmacybusiness_' + itemCounter + '.jpg)"></a></li>';
        }

        function loadMoreMedia() {
            var i = currentLastItem + 1,
                itemsToAdd = [];
            for (; i <= currentLastItem + mediaLoadCounter; i++) {
                if (i > maxItems) {
                    loadMoreBtn.fadeOut();
                    break;
                }

                itemsToAdd.push(mediaItemTemplate(i));
            }

            currentLastItem = i <= maxItems ? currentLastItem += mediaLoadCounter : i;
            mediaList.append(itemsToAdd);

            setTimeout(function() {
                mediaList.find('.hidden').removeClass('hidden');
            }, 300);
        }

        mediaList.magnificPopup({
            removalDelay: 300,
            mainClass: 'mfp-with-zoom mfp-fade',

            callbacks: {

                elementParse: function(item) {
                    if (item.src === '#video-popup') {
                        item.type = 'inline';
                    }
                    if (item.src.indexOf('youtube') > -1) {
                        item.type = 'iframe';
                    }
                },

                open: function() {
                    if (!swipeAdded) {
                        setTimeout(function() {
                            var galleryWrapper = document.querySelector('.mfp-gallery');
                            saveHammerWrapper(galleryWrapper);
                            addSwipeEvents(galleryWrapper);
                        }, 100);
                        swipeAdded = true;
                    }
                },

                beforeClose: function() {
                    if (swipeAdded) {
                        var galleryWrapper = document.querySelector('.mfp-gallery');
                        removeSwipeEvents(galleryWrapper);
                        swipeAdded = false;
                    }

                }
            },

            type: 'image',
            delegate: 'a',
            gallery: {
                enabled: true,
                tPrev: 'Poprzednie',
                tNext: 'Następne',
                tCounter: '%curr% / %total%'
            }

        });

        loadMoreBtn.on('click', loadMoreMedia);

        function saveHammerWrapper(elem) {
            hammerElem = Hammer(elem);
        }

        function addSwipeEvents() {

            if (hammerElem) {
                hammerElem.on("swipeleft", function() {
                    magnificPopup.next();
                });

                hammerElem.on("swiperight", function() {
                    magnificPopup.prev();
                });
            }
        }

        function removeSwipeEvents(elem) {
            hammerElem.off("swiperight swipeleft");
        }

    }



    function Video() {

        var locationHash = location.hash;
        var video = document.getElementById('video'),
            source = video.querySelector('source'),
            sliderVideo = document.getElementById('slider-video');

        if (locationHash === '#video') {
            sliderVideo.play();
            // $.magnificPopup.open({
            //     items: {
            //         src: '#video-popup'
            //     },
            //     type: 'inline',
            //     callbacks: {
            //         open: function() {
            //             if (video) {
            //                 video.play();
            //             }
            //         }
            //     }
            // });
        }

        function setVideo() {

            if (video && !source) {
                var windowWidth = $(window).width(),
                    sourceEl = document.createElement("source");
                sourceEl.setAttribute('type', 'video/mp4');

                if (windowWidth <= 1200) {
                    sourceEl.setAttribute('src', '/bundles/portal/video/480.mp4');
                } else {
                    sourceEl.setAttribute('src', '/bundles/portal/video/1024.mp4');
                }

                video.appendChild(sourceEl);
            }
        }

        setVideo();
    };


    Body();
    Header();
    ScrollTo();
    Tabs();
    Slider();
    Speakers();
    //Register();
    Localization();
    Gallery();
    Video();

})();